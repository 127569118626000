@import "@app/styles/variables";

.root {
  position: relative;
  display: block;
  cursor: pointer;
}

.picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 432px;
  background-color: #eeeeee;
  overflow: hidden;

  @supports (aspect-ratio: 1 / 1) {
    width: 100%;
    padding: unset;
  }
}

.preview-improved,
.preview-improved-with-cover {
  .picture {
    background-color: transparent;
  }
}

.picture-preview {
  position: relative;
  z-index: 1;
  display: block;
  max-height: inherit;
  aspect-ratio: 1 / 1;
}

.picture-with-cover {
  display: block;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.photo-book {
  top: 35.4%;
  left: 36.8%;
  z-index: 1;
  width: 30%;
  height: 30%;
}

.photo-book-customer {
  display: none;
}

.canvas {
  top: 18.5%;
  left: 17%;
  width: 54.7%;
  height: 56%;
}

.mini-book {
  top: 22%;
  left: 20.7%;
  width: 58.3%;
  height: 37%;
}

.photo {
  top: 27%;
  left: 17.5%;
  width: 65%;
  height: 46.2%;
}

.photo-set {
  top: 26.5%;
  left: 26%;
  width: 37.5%;
  height: 53.5%;
  transform: rotate(-2.8deg);
}

.cover {
  position: absolute;
  z-index: 1;
  object-fit: cover;
  object-position: center center;
}

.with-caption .cover {
  z-index: -1;
}

.content {
  padding: 22px 0;
  text-align: center;
}

.title {
  color: var(--color-black);
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  transition: color ease-in-out 150ms;

  .disabled &,
  .root:hover & {
    color: var(--color-smoke-screen);
  }
}

.vertical .cover {
  top: 14.3%;
  left: 23%;
  width: 45%;
  height: 63%;
}

.horizontal .cover {
  top: 22.5%;
  left: 13.6%;
  width: 63%;
  height: 45%;
}

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.rating-count {
  color: var(--color-black);
}

.caption-root {
  bottom: 22%;
  width: 58%;
  height: 19.5%;
  left: 50%;
  transform: translateX(-50%);
}

.caption-with-cover {
  bottom: 26%;
  width: 50%;
  height: 16.5%;
  left: 45%;
  transform: translateX(-45%) skew(0deg, 0.5deg);
  z-index: 3;
}

.photo-book-with-cover {
  position: absolute;
  top: 32.5% !important;
  left: 35% !important;
  width: 28.5% !important;
  height: 29% !important;
  transform: rotate(-50deg) !important;
}

.mini-book-with-cover {
  top: 24% !important;
  left: 21% !important;
  width: 53.3% !important;
  height: 34% !important;
}

.canvas-with-cover {
  top: 19% !important;
  left: 27% !important;
  width: 45% !important;
  height: 61.7% !important;
}

.horizontal {
  .canvas-with-cover {
    top: 29.3% !important;
    left: 21% !important;
    width: 57% !important;
    height: 41% !important;
  }
}

.photo-set-with-cover {
  top: 24.5% !important;
  left: 35.5% !important;
  width: 29% !important;
  height: 42.5% !important;
  transform: rotate(-0.5deg) !important;
}

.horizontal {
  .photo-set-with-cover {
    top: 34.5% !important;
    left: 27.5% !important;
    width: 45.5% !important;
    height: 31.5% !important;
    transform: rotate(-0.2deg) !important;
  }
}

.image-with-cover {
  position: relative;
  z-index: 3;
}
